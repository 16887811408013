    <template>
        <div>
        <div class="pdf-viewer-overlay" @click="closeViewer"></div>
        <div class="pdf-viewer">
            <span class="close-button" @click="closeViewer">
                X
            </span>
            <iframe :src="pdfUrl" frameborder="0" width="100%" height="100%"></iframe>
        </div>
        </div>
    </template>
    
    <script>
    export default {
        props: {
            pdfUrl: {
                type: String,
                required: true
            }
        },
        methods: {
            closeViewer() {
                this.$emit('close');
            }, 
            setFullWidthMode() {
                const iframe = this.$refs.pdfIframe;
                iframe.contentWindow.PDFViewerApplication.pdfViewer.currentScaleValue = 'page-width';
            }
        },
        mounted() {
            this.detectMobile();
            this.setFullWidthMode();
        }
    }
    </script>
    
    <style>
    .pdf-viewer-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
    }
    
    .pdf-viewer {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        height: 80%;
        max-width: 1200px;
        max-height: 800px;
        background-color: white;
        z-index: 1000;
    }
    
    .pdf-viewer iframe {
        width: 100%;
        height: 100%;
    }
    .pdf-viewer > .close-button{
        position: absolute;
        top: 0em;
        right: -1.7em;
        padding: 20px 14px;
        color: var(--light);
        cursor: pointer;
        font-size: var(--font-display-5);
        line-height: 0;
        background: var(--secondary);
    }
    </style>
    