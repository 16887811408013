<template>
    <section>
        <div class="text-justify">
            <h3 class="title">Aviso de Privacidad</h3>

            <p>&Uacute;ltima actualizaci&oacute;n: 30 de Julio del 2019</p>

            <p></p>
            De conformidad con lo establecido en el art&iacute;culo 17 de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares (en lo sucesivo &ldquo;LFPDPPP&rdquo;), le informamos que DRP CLOUD M&Eacute;XICO S.A.P.I. DE C.V. (en lo sucesivo &ldquo;DRPM&eacute;xico &rdquo; o &ldquo;La Responsable&rdquo;) y/o cualquiera de sus representantes, profesionistas, dependientes, empresas subsidiarias, filiales, proveedores, prestadores de servicios, empresas relacionadas, contratistas, becarios (en lo sucesivo los &ldquo;Colaboradores&rdquo;), tratar&aacute;n los datos personales que se recaben de usted o su representada, con la finalidad de de corresponder a una relaci&oacute;n actual y futura de negocios y de su inter&eacute;s por resguardar su informaci&oacute;n, y/o contratar alguno de nuestros servicios, as&iacute; como en su caso la entrega o prestaci&oacute;n de dichos servicios en su domicilio, pudiendo gestionar, administrar y dar de alta en nuestro sistema la relaci&oacute;n con usted para llevar a cabo los servicios y la facturaci&oacute;n seg&uacute;n sea el caso.<br />
            <br />
            Los datos que proporcione a DRPM&eacute;xico corresponder&aacute;n &uacute;nicamente a datos de identificaci&oacute;n, contacto, laborales y en caso de ser necesario financieros los cuales ser&aacute;n &uacute;nicamente difundidos y/o compartidos, cuando la naturaleza de la relaci&oacute;n de negocios que existe entre usted y DRPM&eacute;xico , siempre en el marco de la &eacute;tica y profesionalismo, as&iacute; como de la LFPDPPP. De manera adicional, utilizaremos sus datos personales para las siguientes finalidades que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atenci&oacute;n, tales como uso de su nombre y/o marca &uacute;nicamente para presentaciones, exposiciones y propuestas internas entre usted y/o su representante y DRPM&eacute;xico . En caso de que usted no desee estos fines adicionales, usted puede presentar un escrito manifestando lo anterior.<br />
            <br />
            <b>1. Responsable de la protecci&oacute;n de sus datos personales</b> DRPM&eacute;xico y/o cualquiera de sus &ldquo;Colaboradores&rdquo;, le hacen de su conocimiento que la informaci&oacute;n que proporciona cuando usted utiliza los servicios, representa un elemento importante para nuestra operaci&oacute;n y la prestaci&oacute;n de los mismos servicios, por lo que ser&aacute; manejada como confidencial y la protegeremos a trav&eacute;s de procesos internos, buscando siempre su mayor protecci&oacute;n y resguardo. DRP CLOUD M&Eacute;XICO S.A.P.I. DE C.V. es responsable del tratamiento de los datos personales que le sean recabados de acuerdo a este Aviso de Privacidad, se&ntilde;alando como domicilio para estos fines el ubicado en Av. Am&eacute;ricas 1501 Piso 20-A Col. Providencia, C.P. 44630, Guadalajara, Jalisco.<br />
            <br />
            <b>2. Forma y medios de contacto</b> En t&eacute;rminos de la LFPDPPP, designamos encargado al responsable del manejo de datos personales para que en t&eacute;rminos de la LFPDPPP mencionada sea quien adem&aacute;s de atender las solicitudes que usted como nuestro cliente o usuario nos presente, vigile el tratamiento sus datos personales, por lo que ser&aacute; necesario que para cualquier tipo de asunto para realizar o solicitar, bien sea: comentarios, dudas, actualizaci&oacute;n, cancelaci&oacute;n, revocaci&oacute;n, entre otros, y que sea referente a sus datos personales deber&aacute; ser dirigido al &aacute;rea designada como responsable a trav&eacute;s de un escrito redactado en idioma espa&ntilde;ol y dirigido al responsable previamente designado y enviarlo a la direcci&oacute;n electr&oacute;nica: contacto@drpmexico.com o si desea v&iacute;a correo postal a la direcci&oacute;n: Av. Am&eacute;ricas 1501 Piso 20-A Col. Providencia, C.P. 44630, Guadalajara, Jalisco, todo lo anterior en t&eacute;rminos del presente Aviso de Privacidad. Asimismo, le hacemos saber que para atenderle de acuerdo a lo descrito anteriormente es necesario que usted como nuestro cliente y/o usuario, cumpla con los requisitos que se describen en los art&iacute;culos 29 y 31 de la Ley.<br />
            <br />
            <b>3. Datos recabados y medios de obtenci&oacute;n de Datos Personales</b> Los datos personales que recabamos de usted, con los fines descritos en el presente Aviso de Privacidad, son recabados a trav&eacute;s de diversas maneras, siendo &eacute;stas las m&aacute;s comunes: de forma personal y directa, cuando usted nos los proporciona directamente; v&iacute;a internet; a trav&eacute;s de cualquier comunicaci&oacute;n con nosotros; y cuando obtenemos informaci&oacute;n a trav&eacute;s de otras fuentes permitidas por la LFPDPPP, incluyendo sin limitarse a cuando usted utiliza los servicios, accede a nuestro Sitio Web o a cualquiera de nuestros sistemas de comunicaci&oacute;n.<br />
            <br />
            <b>4. Datos personales</b> Los datos personales que recabamos de forma directa es cuando usted mismo nos los proporciona por cualquier medio, como cuando contrata con nosotros alg&uacute;n servicio. Los datos que recabamos de manera directa y cualquier medio, son los siguientes: nombre, raz&oacute;n social, g&eacute;nero, estado civil, domicilio social, ubicaci&oacute;n, fecha y lugar de nacimiento, profesi&oacute;n y ocupaci&oacute;n, nacionalidad, tel&eacute;fono particular y laboral, m&oacute;vil, correo electr&oacute;nico, RFC, CURP, referencias personales, financieras, profesionales y/o patrimoniales e identificaci&oacute;n, datos de identificaci&oacute;n; reconociendo que usted que tiene el consentimiento de terceros para transferir y tratar sus datos conforme al presente Aviso de Privacidad.<br />
            <br />
            <b>5. Fines de los Datos Personales recabados</b> Los datos personales recabados son para ser empleados por la Responsable para los siguientes fines: prestaci&oacute;n de los servicios, elaborar un registro, identificar al usuario para otorgarle acceso a sus servicios, fines administrativos (facturaci&oacute;n y seguimiento al cliente, entregas a domicilio, entre otros), seguimiento, mercadeo, monitoreo y confirmaci&oacute;n en cuanto a los servicios contratados, registro de servicios, realizar investigaciones, realizar y enviar informaci&oacute;n comercial, publicitaria y promociones (incluidos los env&iacute;os por correo electr&oacute;nico, telefon&iacute;a celular SMS, MMS, entre otros y todo medio de comunicaci&oacute;n electr&oacute;nica similar o que pueda desarrollarse, evaluar la calidad de los servicios, recopilar preferencias expresadas y configuraciones seleccionadas, informaci&oacute;n sobre c&oacute;mo usted y los visitantes del Sitio Web interact&uacute;an con nuestros servicios, personalizar y mejorar los servicios, ofrecer otros similares y publicidad, realizar el cobro de cualquier cantidad y dem&aacute;s gastos aplicables a los servicios, y cualquier otra actividad tendiente a promover, mantener, mejorar y evaluar los servicios de la Responsable, como encuestas de calidad y satisfacci&oacute;n, prevenir el fraude y abuso de nuestros servicios, solucionar los errores de software y problemas operacionales. Le reiteramos que los datos personales son manejados con absoluta confidencialidad y todo tratamiento como: el uso, la divulgaci&oacute;n, as&iacute; como su almacenamiento se realiza para cumplir con los fines ya descritos.<br />
            <br />
            <b>6. Resguardo de datos</b> Los datos personales que nos proporciona, se guardan en bases de datos controladas y con acceso limitado. En virtud de lo anterior, a partir del momento en que nos otorga su consentimiento, la Responsable podr&aacute; mantener en sus registros, tanto f&iacute;sicos como electr&oacute;nicos, toda la informaci&oacute;n que nos haya facilitado usted a trav&eacute;s de cualquier medio de los puestos a su disposici&oacute;n por la Responsable a fin de recabar sus datos personales.<br />
            <br />
            Los empleados y colaboradores de DRPMX, a su vez han suscrito convenio de confidencialidad donde se comprometen y obligan al buen uso de la informaci&oacute;n confidencial que usted como usuario nos proporciona y a la no divulgaci&oacute;n sin previo aviso de dicha informaci&oacute;n.<br />
            Hacemos de su conocimiento que la salvaguarda de los Datos Personales en internet no se puede garantizar al 100%; sin embargo una vez recibidos, se har&aacute; todo lo posible por salvaguardarla implementando medidas t&eacute;cnicas, administrativas y f&iacute;sicas para el resguardo y seguridad de la informaci&oacute;n.<br />
            <br />
            <b>7. Transferencia de datos</b> La informaci&oacute;n solicitada permite a la Responsable contactar a nuestros clientes o usuarios y afiliados, para procurar un servicio eficiente, y lograr cualquiera de los fines descritos anteriormente. Asimismo, le informamos que para dar cumplimiento a las obligaciones derivadas de la relaci&oacute;n jur&iacute;dica entre la Responsable y usted, as&iacute; como aquellas finalidades previstas en el presente Aviso de Privacidad, usted autoriza a la Responsable para transferir los datos personales necesarios a autoridades competentes en los casos que legalmente sea requerido; y si usted no manifiesta su negativa para transferencias y acepta el presente Aviso de Privacidad, entenderemos que nos ha autorizado para adem&aacute;s transferirle datos e informaci&oacute;n a terceros tanto nacionales como extranjeros que intervengan en la prestaci&oacute;n de los servicios, siendo estos los Colaboradores de la Responsable antes mencionados; lo anterior, ser&aacute; realizado de conformidad con los principios contenidos en la LFPDPPP y regulaci&oacute;n relacionada. La informaci&oacute;n proporcionada por la Responsable siempre ser&aacute; empleada conforme a lo establecido en el presente Aviso de Privacidad.<br />
            <br />
            <b>8. Ejercicio de los Derechos ARCO (acceso, rectificaci&oacute;n, cancelaci&oacute;n y/u oposici&oacute;n)</b> Usted tiene derecho de acceder a los datos personales que poseemos suyos, a los detalles de tratamiento de los mismos, a la rectificaci&oacute;n en el caso de ser estos inexactos o incompletos, a cancelarlos cuando sea su consideraci&oacute;n que no son necesarios para alguna de las finalidades contenidas en el presente Aviso de Privacidad, que son utilizados para finalidades no consentidas y finalmente a oponerse al tratamiento de dichos datos para fines espec&iacute;ficos y que deber&aacute; de manera clara expresar en la forma y a trav&eacute;s de los medios establecidos en el apartado &ldquo;Mecanismos para el ejercicio de los Derechos ARCO&rdquo; (Derechos &ldquo;ARCO&rdquo;).<br />
            <br />
            <b>9. Mecanismos para el ejercicio de los Derechos ARCO</b> Para conocer los procedimientos, requisitos y plazos necesarios para el ejercicio de los Derechos ARCO se puede poner en contacto con nuestro departamento de datos personales a trav&eacute;s del correo electr&oacute;nico contacto@drpmexico.com. Para el ejercicio de los Derechos ARCO podr&aacute; solicitar los formularios correspondientes directamente con nuestro responsable del departamento de manejo de datos personales. En dichos formatos encontrar&aacute; los pasos a seguir para poder ejercer sus derechos. Toda la respuesta y/o documentaci&oacute;n se entregar&aacute; en formato electr&oacute;nico o f&iacute;sico. Usted recibir&aacute; respuesta sobre su solicitud en forma en un plazo m&aacute;ximo de veinte d&iacute;as contados a partir de la fecha en que se recibi&oacute; la solicitud de acceso, rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n, la determinaci&oacute;n adoptada, a efecto de que, si resulta procedente, se haga efectiva la misma dentro de los quince d&iacute;as siguientes a la fecha en que se comunica la respuesta. Trat&aacute;ndose de solicitudes de acceso a datos personales, proceder&aacute; la entrega previa acreditaci&oacute;n de la identidad del solicitante o representante legal, seg&uacute;n corresponda. Toda la documentaci&oacute;n se entregar&aacute; en formato electr&oacute;nico o f&iacute;sico. El derecho al acceso a la informaci&oacute;n estar&aacute; sujeto a que dicha informaci&oacute;n se encuentre disponible en nuestros servidores y expedientes, en caso de tenerlos, en todo caso DRPM&eacute;xico destruir&aacute;, cancelar&aacute; y no tendr&aacute; informaci&oacute;n disponible en estos t&eacute;rminos por m&aacute;s de un a&ntilde;o. Ya que los plazos de conservaci&oacute;n no deber&aacute;n exceder aqu&eacute;llos que sean necesarios para el cumplimiento de las finalidades aqu&iacute; descritas.<br />
            <br />
            <b>10. Derecho de revocar su consentimiento para el tratamiento de datos</b> Con las limitaciones aqu&iacute; expresadas, en todo tiempo usted tiene derecho a revocar el consentimiento que nos ha entregado para el tratamiento de sus datos personales con el prop&oacute;sito que se deje de hacer uso de los mismos. Para este efecto es necesario que se presente su petici&oacute;n al Responsable a trav&eacute;s del correo electr&oacute;nico contacto@drpmexico.com, seg&uacute;n lo descrito en este mismo aviso en el apartado &ldquo;Forma y medios de Contacto&rdquo;. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender a su solicitud o cancelar el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus daros personales. Asimismo, usted deber&aacute; considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando el servicio que nos solicit&oacute;, o la conclusi&oacute;n de su relaci&oacute;n con nosotros.<br />
            <br />
            <b>11. Uso de cookies, web beacons o cualquier otra tecnolog&iacute;a similar</b> Le informamos que en nuestro Sitio Web utilizamos cookies, web beacons y otras tecnolog&iacute;as a trav&eacute;s de las cuales es posible monitorear su comportamiento como usuario de Internet, brindarle un mejor servicio y experiencia de usuario al navegar en nuestro Sitio Web, as&iacute; como ofrecerle nuevos servicios basados en sus preferencias. Los datos personales que obtenemos de estas tecnolog&iacute;as de rastreo, entre otras, son las siguientes: horario de navegaci&oacute;n, tiempo de navegaci&oacute;n en nuestro Sitio Web, secciones consultadas, y p&aacute;ginas de Internet accedidas previo a la nuestra.<br />
            <br />
            <b>12. Modificaciones al presente aviso de privacidad</b> Nos queda reservado el derecho a modificar o actualizar en cualquier tiempo, el presente Aviso de Privacidad, por lo que hacemos de su conocimiento que cualquier cambio o modificaci&oacute;n al contenido del mismo le ser&aacute; comunicada oportunamente a trav&eacute;s de cualquier v&iacute;a, ya sea el Sitio Web, v&iacute;a correo electr&oacute;nico a la direcci&oacute;n m&aacute;s reciente que tengamos de usted, a trav&eacute;s de las diversas herramientas tecnol&oacute;gicas utilizadas por la Responsable o personalmente por medio de nuestro personal.<br />
            <br />
            <b>13. Lesi&oacute;n de datos personales</b> Si usted considera que su derecho a la protecci&oacute;n de sus datos ha sido lesionado por alguna conducta u omisi&oacute;n de nuestra parte o presume alguna violaci&oacute;n a las disposiciones previstas en la LFPDPPP, su Reglamento y dem&aacute;s ordenamientos aplicables, podr&aacute; interponer su inconformidad o denuncia ante el Instituto Nacional de Transparencia y Protecci&oacute;n de Datos Personales (en lo sucesivo &ldquo;INAI&rdquo;). Para mayor informaci&oacute;n, le sugerimos visitar su p&aacute;gina oficial de Internet www.inai.org.mx<br />
            <br />
            <b>14. Aceptaci&oacute;n del aviso de privacidad</b> Al recibir nuestros servicios o acceder al Sitio Web, significa que el usuario manifiesta que conoce, ha le&iacute;do, entendido y acordado, los fines y alcances del presente Aviso de Privacidad, incluido el apartado de transferencia y divulgaci&oacute;n de datos personales en los t&eacute;rminos indicados en el mencionado Aviso de Privacidad. Por lo anterior, otorga su total consentimiento expreso para que DRPM&eacute;xico lleve a cabo el tratamiento de sus datos personales en los t&eacute;rminos de este Aviso de Privacidad.<br />
            &nbsp;
        </div>
    </section>
</template>

<script>
export default {
    mounted(){
        location.href = '#'
    }
}
</script>

<style>

</style>