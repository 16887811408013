<template>
    <div class="page-preloader">

    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>