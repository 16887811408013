<template>
    <div class="p-fixed bottom-0 right-0">
        <a class="btn" href="#">&uarr;</a>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.p-fixed{
    z-index: 99;
    padding: 1em;
    opacity: 0.7;
}
a{
    border-radius: 0;
    padding: 10px 18px;
}
</style>