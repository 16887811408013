<template>
    <section>
        <div class="text-justify">
            <h1>Acuerdo de nivel de servicio inform&aacute;tico de DRPMX</h1>

            <p></p>

            <p>&Uacute;ltima actualizaci&oacute;n: 17 de marzo del 2022</p>

            <p></p>

            <p>Este Acuerdo de nivel de servicio de DRPMX Compute (este &quot;SLA&quot;) es una pol&iacute;tica que rige el uso de los Servicios incluidos (enumerados a continuaci&oacute;n) y se aplica por separado a cada cuenta que utiliza los Servicios incluidos. En caso de conflicto entre los t&eacute;rminos de este SLA y los t&eacute;rminos del Acuerdo de cliente de DRPMX u otro acuerdo con nosotros que rija el uso de nuestros Servicios (el &quot;Acuerdo&quot;), se aplicar&aacute;n los t&eacute;rminos y condiciones de este SLA, pero solo para el alcance de tal conflicto. Los t&eacute;rminos en may&uacute;scula utilizados aqu&iacute; pero no definidos aqu&iacute; tendr&aacute;n los significados establecidos en el Acuerdo.</p>

            <p></p>

            <h3><strong>Servicios incluidos:</strong></h3>

            <ul>
                <li>Infrastructure as a Service (IaaS)</li>
                <li>Backup as a Service (BaaS)</li>
                <li>Disaster Recovery as a Service (DRaaS)</li>
                <li>Microsoft Protection as a Service (MPaaS)</li>
            </ul>

            <p></p>

            <h3><strong>SLA</strong></h3>

            <p>Para los servicios incluidos en todas las instancias o tareas en ejecuci&oacute;n implementadas, DRPMX har&aacute; todos los esfuerzos comercialmente razonables para que cada Servicio incluido est&eacute; disponible con un Porcentaje de tiempo de actividad mensual de al menos el 99.9 %, de manera mensual. En caso de que alguno de los Servicios incluidos no cumpla con el SLA, ser&aacute; elegible para recibir un Cr&eacute;dito de servicio como se describe a continuaci&oacute;n.</p>

            <p></p>

            <table border="0" cellpadding="0" cellspacing="0" style="width:579px;" width="579">
                <tbody>
                    <tr>
                        <td nowrap="nowrap" style="width:348px;height:47px;">
                        <p><strong>Porcentaje de tiempo de actividad mensual</strong></p>
                        </td>
                        <td nowrap="nowrap" style="width:231px;height:47px;">
                        <p><strong>Porcentaje de cr&eacute;dito de servicio</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td nowrap="nowrap" style="width:348px;height:47px;">
                        <p>Inferior al 99.9% pero igual o superior al 99.0%</p>
                        </td>
                        <td nowrap="nowrap" style="width:231px;height:47px;">
                        <p>10%</p>
                        </td>
                    </tr>
                    <tr>
                        <td nowrap="nowrap" style="width:348px;height:47px;">
                        <p>Inferior al 99.0% pero igual o superior al 95.0%</p>
                        </td>
                        <td nowrap="nowrap" style="width:231px;height:47px;">
                        <p>30%</p>
                        </td>
                    </tr>
                    <tr>
                        <td nowrap="nowrap" style="width:348px;height:47px;">
                        <p>Menos del 95.0%</p>
                        </td>
                        <td nowrap="nowrap" style="width:231px;height:47px;">
                        <p>100%</p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p></p>

            <h3><strong>Cr&eacute;ditos SLA</strong></h3>

            <p>Los Cr&eacute;ditos de servicio se calculan como un porcentaje de la factura mensual (excluidos los pagos &uacute;nicos, como los pagos por servicios profesionales) por cada Servicio contratado de manera individual, el cual DRPMX no cumpli&oacute; con el SLA.</p>

            <p></p>

            <p>Aplicaremos los Cr&eacute;ditos de servicio solo contra pagos futuros por el Servicio incluido aplicable que de lo contrario usted adeudar&iacute;a. Los Cr&eacute;ditos de servicio no le dar&aacute;n derecho a ning&uacute;n reembolso u otro pago de DRPMX. Se aplicar&aacute; y se emitir&aacute; un Cr&eacute;dito de servicio solo si el monto del cr&eacute;dito para el ciclo de facturaci&oacute;n mensual aplicable es superior a un d&oacute;lar ($1 USD). Los Cr&eacute;ditos de servicio no se pueden transferir ni aplicar a ninguna otra cuenta u organizaci&oacute;n.</p>

            <p></p>

            <h3><strong>Solicitud de Cr&eacute;dito y Procedimientos de Pago</strong></h3>

            <p>Para recibir un Cr&eacute;dito de servicio, debe abrir un caso en el portal de soporte de DRPMX <a href="https://support.drpmexico.com">https://support.drpmexico.com</a>. Solo puede enviar un reclamo bajo el SLA, ya que estos reclamos no se pueden combinar ni apilar. Su solicitud de cr&eacute;dito debe ser recibida por nosotros al final del segundo ciclo de facturaci&oacute;n despu&eacute;s del cual ocurri&oacute; el incidente y debe incluir la informaci&oacute;n especificada a continuaci&oacute;n.</p>

            <ul>
                <li>Servicio contratado</li>
                <li>Fecha y hora del inicio del incidente</li>
                <li>Fecha y hora del fin del incidente</li>
                <li>Numero del incidente reportado en el portal de soporte de DRPMX</li>
                <li>Descripci&oacute;n detallada del incumplimiento del SLA.</li>
            </ul>

            <p></p>

            <p>Si confirmamos que un reclamo bajo el SLA de nivel de regi&oacute;n o de instancia es v&aacute;lido, le emitiremos un cr&eacute;dito de servicio dentro de un ciclo de facturaci&oacute;n posterior al mes en que confirmemos su solicitud.</p>

            <p></p>

            <p>Si no proporciona la informaci&oacute;n solicitada y otra informaci&oacute;n requerida anteriormente, lo descalificar&aacute; para recibir un Cr&eacute;dito de servicio. A menos que se indique lo contrario en el Acuerdo, este SLA establece sus recursos &uacute;nicos y exclusivos, y las obligaciones &uacute;nicas y exclusivas de DRPMX, por cualquier falta de disponibilidad, incumplimiento u otra falla por nuestra parte en la prestaci&oacute;n de los Servicios incluidos.</p>

            <p></p>

            <h3><strong>Exclusiones del SLA de DRPMX</strong></h3>

            <p></p>

            <p>El SLA, no se aplican a ninguna falta de disponibilidad, suspensi&oacute;n o terminaci&oacute;n de un Servicio incluido, ni a ning&uacute;n otro problema de rendimiento del Servicio incluido, directa o indirectamente: (i) causado por factores fuera de nuestro control, incluido cualquier evento de fuerza mayor o acceso a Internet o problemas relacionados m&aacute;s all&aacute; del punto de demarcaci&oacute;n del Servicio Incluido aplicable; (ii) que resulten de cualquier acci&oacute;n o inacci&oacute;n de usted o de un tercero, incluida la falta de reconocimiento de un volumen de recuperaci&oacute;n o la falta de respuesta a inquietudes sobre la salud de los recursos; (iii) que resulten de su equipo, software u otra tecnolog&iacute;a y/o equipo, software u otra tecnolog&iacute;a de terceros (que no sean equipos de terceros bajo nuestro control directo); o (iv) que surja de nuestra suspensi&oacute;n o rescisi&oacute;n de su derecho a utilizar el Servicio incluido aplicable de conformidad con el Acuerdo (colectivamente, las &quot;Exclusiones del SLA de DRPMX Compute&quot;). Si la disponibilidad se ve afectada por factores distintos a los utilizados en nuestro c&aacute;lculo del Porcentaje de tiempo de actividad mensual, podemos emitir un Cr&eacute;dito de servicio considerando dichos factores a nuestra discreci&oacute;n.</p>

            <h2></h2>

            <h3><strong>Definiciones de SLA</strong></h3>

            <ul>
                <li>&quot;Zona de disponibilidad&quot; y &quot;AZ&quot; significan una ubicaci&oacute;n aislada dentro de una regi&oacute;n de DRPMX identificada por un identificador de letras que sigue al c&oacute;digo de regi&oacute;n de DRPMX (por ejemplo, MXGDL o MXMTY).</li>
                <li>El &ldquo;Porcentaje de Uptime Mensual&rdquo; se calcula restando al 100% el porcentaje de minutos durante el mes en que alguno de los Servicios Incluidos, seg&uacute;n corresponda, estuvo en estado de Indisponibilidad.</li>
                <li>Un &quot;Cr&eacute;dito de servicio&quot; es un cr&eacute;dito en d&oacute;lares, calculado como se establece anteriormente, que podemos devolver a una cuenta elegible.</li>
                <li>&quot;No disponible&quot; e &quot;Indisponibilidad&quot; significan: Para el SLA aplicable a DRPMX, cuando todas sus instancias en ejecuci&oacute;n o tareas en ejecuci&oacute;n, seg&uacute;n corresponda, no tienen conectividad externa al mismo tiempo.</li>
            </ul>
        </div>
    </section>
</template>

<script>
export default {
    mounted(){
        location.href = '#'
    }
}
</script>

<style>

</style>