<template>
  <section>
    <div class="text-justify">
      <h1 class="title">Acuerdo de cliente</h1>

      <p>&Uacute;ltima actualizaci&oacute;n: 1 de enero del 2024</p>

      <p></p>

      <p></p>

      <p>
        Este Acuerdo de cliente de DRPM&eacute;xico (este
        &quot;<strong>Acuerdo</strong>&quot;) contiene los t&eacute;rminos y
        condiciones que rigen su acceso y uso de los Servicios (como se define a
        continuaci&oacute;n) y es un acuerdo entre la Parte contratante de
        DRPM&eacute;xico correspondiente que se especifica en la Secci&oacute;n
        15 a continuaci&oacute;n (tambi&eacute;n denominada como
        &quot;<strong>DRPM&eacute;xico</strong>&quot;,
        &quot;<strong>nosotros</strong>&quot; o
        &quot;<strong>nuestro</strong>&quot;) y usted o la entidad que
        representa (&quot;<strong>usted</strong>&quot; o
        &quot;<strong>su</strong>&quot;). Este Acuerdo entra en vigor cuando
        acepta una cotizaci&oacute;n o utiliza cualquiera de los Servicios de
        acuerdo con (la &quot;Fecha de vigencia&quot;). Usted nos manifiesta que
        est&aacute; legalmente capacitado para celebrar contratos (por ejemplo,
        no es menor de edad). Si est&aacute; celebrando este Acuerdo para una
        entidad, como la empresa para la que trabaja, nos declara que tiene
        autoridad legal para vincular a esa entidad. Consulte la Secci&oacute;n
        15 para conocer las definiciones de ciertos t&eacute;rminos en
        may&uacute;scula utilizados en este Acuerdo.
      </p>

      <p></p>

      <h3>1. Uso de los Servicios.</h3>

      <p>
        1.1 <strong>Generalmente</strong>. Puede acceder y utilizar los
        Servicios de acuerdo con este Acuerdo. Los Acuerdos de nivel de servicio
        y los T&eacute;rminos de servicio se aplican a determinados Servicios.
        Cumplir&aacute; con los t&eacute;rminos de este Acuerdo y todas las
        leyes, reglas y regulaciones aplicables a su uso de los Servicios.
      </p>

      <p></p>

      <p>
        1.2 <strong>Su cuenta</strong>. Para acceder a los Servicios, debe tener
        una cuenta en DRPM&eacute;xico asociada con una direcci&oacute;n de
        correo electr&oacute;nico v&aacute;lida y un contrato vigente.
      </p>

      <p></p>

      <p>
        1.3 <strong>Contenido de terceros</strong>. Usted puede utilizar el
        Contenido de terceros a su elecci&oacute;n. El Contenido de terceros se
        rige por este Acuerdo y, si corresponde, los t&eacute;rminos y
        condiciones separados que acompa&ntilde;an a dicho Contenido de
        terceros, cuyos t&eacute;rminos y condiciones pueden incluir tarifas y
        cargos separados.
      </p>

      <p></p>

      <h3>2. Cambios.</h3>

      <p>
        2.1 <strong>A los Servicios</strong>. Podemos cambiar o descontinuar
        cualquiera de los Servicios de vez en cuando. Le proporcionaremos un
        aviso con al menos 12 meses de anticipaci&oacute;n si descontinuamos la
        funcionalidad material de un Servicio que est&aacute; usando, o si
        modificamos materialmente una API de cara al cliente que est&aacute;
        usando de una manera incompatible con versiones anteriores, excepto que
        este aviso no ser&aacute; se requiere si el per&iacute;odo de
        notificaci&oacute;n de 12 meses (a) plantear&iacute;a un problema de
        seguridad o propiedad intelectual para nosotros o los Servicios, (b) es
        econ&oacute;mica o t&eacute;cnicamente oneroso, o (c) nos har&iacute;a
        violar los requisitos legales.
      </p>

      <p></p>

      <p>
        2.2 <strong>A los acuerdos de nivel de servicio</strong>. Podemos
        cambiar, descontinuar o agregar Acuerdos de nivel de servicio de vez en
        cuando de acuerdo con la Secci&oacute;n 12.
      </p>

      <p></p>

      <h3>3. Seguridad y privacidad de los datos.</h3>

      <p>
        3.1 <strong>Seguridad</strong>. Sin limitar la Secci&oacute;n 10 o sus
        obligaciones en virtud de la Secci&oacute;n 4.2, implementaremos medidas
        razonables y apropiadas dise&ntilde;adas para ayudarlo a proteger Su
        Contenido contra p&eacute;rdida, acceso o divulgaci&oacute;n accidental
        o ilegal.
      </p>

      <p></p>

      <p>
        3.2 <strong>Privacidad de datos</strong>. Puede especificar las regiones
        DRPM&eacute;xico en las que se almacenar&aacute; su contenido. Usted da
        su consentimiento para el almacenamiento de Su Contenido y la
        transferencia de Su Contenido a las regiones DRPM&eacute;xico que
        seleccione. No accederemos ni usaremos Su Contenido excepto cuando sea
        necesario para mantener o proporcionar los Servicios, o seg&uacute;n sea
        necesario para cumplir con la ley o una orden vinculante de un organismo
        gubernamental. No (a) divulgaremos Su Contenido a ning&uacute;n gobierno
        o tercero ni (b) sujeto a la Secci&oacute;n 3.3, trasladaremos Su
        Contenido de las regiones DRPM&eacute;xico seleccionadas por usted;
        excepto en cada caso seg&uacute;n sea necesario para cumplir con la ley
        o una orden vinculante de un organismo gubernamental. A menos que
        infrinja la ley o una orden vinculante de un organismo gubernamental, le
        notificaremos de cualquier requisito u orden legal a los que se hace
        referencia en esta Secci&oacute;n 3.2. Solo usaremos la
        informaci&oacute;n de su cuenta de acuerdo con el Aviso de privacidad, y
        usted da su consentimiento para dicho uso. El Aviso de privacidad no se
        aplica a Su contenido.
      </p>

      <p></p>

      <p>
        3.3 <strong>Atributos del servicio</strong>. Para proporcionar Servicios
        de facturaci&oacute;n y administraci&oacute;n, podemos procesar
        Atributos de servicio en las regiones DRPM&eacute;xico donde utiliza los
        Servicios y las regiones DRPM&eacute;xico. Para brindarle Servicios de
        soporte iniciados por usted e investigar fraude, abuso o violaciones de
        este Acuerdo, podemos procesar Atributos de servicio donde mantenemos
        nuestro personal de soporte e investigaci&oacute;n.
      </p>

      <p></p>

      <h3>4. Sus responsabilidades.</h3>

      <p>
        4.1 <strong>Sus cuentas. </strong>Excepto en la medida en que sea
        causado por nuestro incumplimiento de este Acuerdo, (a) usted es
        responsable de todas las actividades que ocurran en su cuenta,
        independientemente de si las actividades est&aacute;n autorizadas por
        usted o realizadas por usted, sus empleados o un tercero (incluido su
        contratistas, agentes o usuarios finales) y (b) nosotros y nuestros
        afiliados no somos responsables del acceso no autorizado a su cuenta.
      </p>

      <p></p>

      <p>
        4.2 <strong>Su contenido</strong>. Se asegurar&aacute; de que su
        contenido y el uso que usted y los usuarios finales hagan de su
        contenido o de las ofertas de Servicios no violar&aacute;n ninguna de
        las pol&iacute;ticas ni ninguna ley aplicable. Usted es el &uacute;nico
        responsable del desarrollo, el contenido, la operaci&oacute;n, el
        mantenimiento y el uso de su contenido.
      </p>

      <p></p>

      <p>
        4.3 <strong>Su seguridad y respaldo</strong>. Usted es responsable de
        configurar y utilizar correctamente los Servicios y de tomar las medidas
        adecuadas para asegurar, proteger y realizar copias de seguridad de sus
        cuentas y Su contenido de una manera que proporcione la seguridad y
        protecci&oacute;n adecuadas, lo que podr&iacute;a incluir el uso de
        cifrado para proteger Su contenido de personas no autorizadas. acceder y
        archivar rutinariamente su contenido.
      </p>

      <p></p>

      <p>
        4.4
        <strong
          >Credenciales de inicio de sesi&oacute;n y claves de cuenta</strong
        >. Las credenciales de inicio de sesi&oacute;n de DPRMX y las claves
        privadas generadas por los Servicios son solo para su uso interno y no
        las vender&aacute;, transferir&aacute; ni sublicenciar&aacute; a ninguna
        otra entidad o persona, excepto que puede divulgar su clave privada a
        sus agentes y subcontratistas que realicen trabajos en su nombre.
      </p>

      <p></p>

      <p>
        4.5 <strong>Usuarios finales</strong>. Se considerar&aacute; que ha
        realizado cualquier acci&oacute;n que permita, ayude o facilite a
        cualquier persona o entidad en relaci&oacute;n con este Acuerdo, Su
        Contenido o el uso de los Servicios. Usted es responsable del uso que
        los usuarios finales hagan de su contenido y los Servicios. Se
        asegurar&aacute; de que todos los Usuarios finales cumplan con sus
        obligaciones en virtud de este Acuerdo y de que los t&eacute;rminos de
        su acuerdo con cada Usuario final sean coherentes con este Acuerdo. Si
        tiene conocimiento de cualquier violaci&oacute;n de sus obligaciones en
        virtud de este Acuerdo causada por un Usuario final, suspender&aacute;
        inmediatamente el acceso a Su Contenido y a los Servicios por parte de
        dicho Usuario final. No proporcionamos ning&uacute;n soporte o servicio
        a los Usuarios finales a menos que tengamos un acuerdo por separado con
        usted o un Usuario final que nos obligue a brindar dicho soporte o
        Servicios.
      </p>

      <p></p>

      <h3>5. Tarifas y pago.</h3>

      <p>
        5.1 <strong>Tarifas de servicio</strong>. Calculamos y facturamos
        tarifas y cargos mensualmente. Es posible que le facturemos con
        m&aacute;s frecuencia las tarifas acumuladas si sospechamos que su
        cuenta es fraudulenta o corre el riesgo de impago. Usted nos
        pagar&aacute; las tarifas y los cargos aplicables por el uso de los
        Servicios como se describe en el Sitio DRPM&eacute;xico utilizando uno
        de los m&eacute;todos de pago que admitimos. Todos los montos pagaderos
        por usted en virtud de este Acuerdo se nos pagar&aacute;n sin
        compensaci&oacute;n ni contrademanda, y sin ninguna deducci&oacute;n o
        retenci&oacute;n. Las tarifas y cargos por cualquier Servicio nuevo o
        nueva caracter&iacute;stica de un Servicio entrar&aacute;n en vigor
        cuando publiquemos tarifas y cargos actualizados en el Sitio
        DRPM&eacute;xico, a menos que indiquemos expresamente lo contrario en un
        aviso. Podemos aumentar o agregar nuevas tarifas y cargos por cualquier
        Servicio existente que est&eacute; utilizando, notific&aacute;ndole con
        al menos 30 d&iacute;as de anticipaci&oacute;n. Podemos optar por
        cobrarle intereses a una tasa del 1.5% por mes sobre todos los pagos
        atrasados.
      </p>

      <p></p>

      <p>
        5.2 <strong>Impuestos</strong>. Cada parte ser&aacute; responsable,
        seg&uacute;n lo requiera la ley aplicable, de identificar y pagar todos
        los impuestos y otras tarifas y cargos gubernamentales (y cualquier
        penalizaci&oacute;n, inter&eacute;s y otras adiciones a los mismos) que
        se impongan a esa parte sobre o con respecto a las transacciones y pagos
        en virtud de este Acuerdo. Todas las tarifas pagaderas por usted no
        incluyen los impuestos indirectos, excepto cuando la ley aplicable
        requiera lo contrario. Podemos cobrarle y usted pagar&aacute; los
        impuestos indirectos aplicables que estamos legalmente obligados o
        autorizados a cobrarle. Nos proporcionar&aacute; la informaci&oacute;n
        que sea razonablemente necesaria para determinar si estamos obligados a
        cobrarle impuestos indirectos. No recaudaremos, y usted no
        pagar&aacute;, ning&uacute;n Impuesto Indirecto por el que nos
        proporcione un certificado de exenci&oacute;n debidamente completado o
        un certificado de permiso de pago directo por el cual podamos reclamar
        una exenci&oacute;n disponible de dicho Impuesto Indirecto. Todos los
        pagos que nos realice en virtud de este Acuerdo ser&aacute;n libres y
        libres de cualquier deducci&oacute;n o retenci&oacute;n, seg&uacute;n lo
        requiera la ley. Si se requiere una deducci&oacute;n o retenci&oacute;n
        de este tipo (incluidas, entre otras, las retenciones en la fuente
        transfronterizas) en cualquier pago, usted pagar&aacute; las cantidades
        adicionales que sean necesarias para que la cantidad neta recibida por
        nosotros sea igual a la cantidad adeuda y pagadera en ese momento bajo
        este acuerdo. Le proporcionaremos los formularios de impuestos que se
        soliciten razonablemente para reducir o eliminar el monto de cualquier
        retenci&oacute;n o deducci&oacute;n de impuestos con respecto a los
        pagos realizados en virtud de este Acuerdo.
      </p>

      <p></p>

      <h3>6. Suspensi&oacute;n temporal.</h3>

      <p>
        6.1 <strong>Generalmente</strong>. Podemos suspender su derecho o el de
        cualquier Usuario final de acceder o utilizar cualquier parte o todas
        los Servicios inmediatamente despu&eacute;s de que le notifiquemos si
        determinamos:
      </p>

      <p></p>

      <ol style="list-style-type: lower-alpha">
        <li>
          su uso o el de un Usuario final de los Servicios (i) representa un
          riesgo de seguridad para los Servicios o cualquier tercero, (ii)
          podr&iacute;a afectar negativamente nuestros sistemas, los Servicios o
          los sistemas o el Contenido de cualquier otro cliente de
          DRPM&eacute;xico, (iii) podr&iacute;a someternos a nosotros, nuestras
          afiliadas o cualquier tercero a responsabilidad, o (iv) podr&iacute;a
          ser fraudulento;
        </li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-alpha">
        <li value="2">
          usted o cualquier Usuario final est&aacute; incumpliendo este Acuerdo;
        </li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-alpha">
        <li value="3">
          est&aacute; incumpliendo sus obligaciones de pago seg&uacute;n la
          Secci&oacute;n 5; o
        </li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-alpha">
        <li value="4">
          ha dejado de operar en el curso ordinario, ha realizado una
          cesi&oacute;n en beneficio de los acreedores o una disposici&oacute;n
          similar de sus activos, o ha sido objeto de cualquier procedimiento de
          quiebra, reorganizaci&oacute;n, liquidaci&oacute;n, disoluci&oacute;n
          o procedimiento similar.
        </li>
      </ol>

      <p></p>

      <p>
        6.2 <strong>Efecto de la suspensi&oacute;n</strong>. Si suspendemos su
        derecho a acceder o utilizar cualquier parte o todas las Ofertas de
        Servicios:
      </p>

      <p></p>

      <ol style="list-style-type: lower-alpha">
        <li>
          usted sigue siendo responsable de todas las tarifas y cargos en los
          que incurra durante el per&iacute;odo de suspensi&oacute;n; y
        </li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-alpha">
        <li value="2">
          no tendr&aacute; derecho a ning&uacute;n cr&eacute;dito de servicio en
          virtud de los Acuerdos de nivel de servicio durante ning&uacute;n
          per&iacute;odo de suspensi&oacute;n.
        </li>
      </ol>

      <p></p>

      <p></p>

      <h3>7. Plazo; Terminaci&oacute;n.</h3>

      <p>
        7.1 <strong>Plazo</strong>. El t&eacute;rmino de este Acuerdo
        comenzar&aacute; en la Fecha de vigencia y permanecer&aacute; en
        vigencia hasta que sea terminado bajo esta Secci&oacute;n 7. Cualquier
        notificaci&oacute;n de terminaci&oacute;n de este Acuerdo por cualquiera
        de las partes a la otra debe incluir una Fecha de terminaci&oacute;n que
        cumpla con los per&iacute;odos de notificaci&oacute;n en la
        Secci&oacute;n 7.2.
      </p>

      <p></p>

      <p>7.2 <strong>Terminaci&oacute;n</strong>.</p>

      <p></p>

      <ol style="list-style-type: lower-alpha">
        <li>
          Terminaci&oacute;n por conveniencia. Puede rescindir este Acuerdo por
          cualquier motivo notific&aacute;ndonos y cerrando su cuenta para todos
          los Servicios para los que proporcionamos un mecanismo de cierre de
          cuenta. Podemos rescindir este Acuerdo por cualquier motivo
          proporcion&aacute;ndole un aviso con al menos 30 d&iacute;as de
          anticipaci&oacute;n.
        </li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-alpha">
        <li value="2">Terminaci&oacute;n por Causa.</li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-roman">
        <li>
          Por cualquiera de las partes. Cualquiera de las partes puede rescindir
          este Acuerdo por causa justificada si la otra parte incumple
          sustancialmente este Acuerdo y el incumplimiento sustancial permanece
          sin subsanar durante un per&iacute;odo de 30 d&iacute;as a partir de
          la recepci&oacute;n de la notificaci&oacute;n por parte de la otra
          parte. A m&aacute;s tardar en la Fecha de terminaci&oacute;n,
          cerrar&aacute; su cuenta.
        </li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-roman">
        <li value="2">
          Por nosotros. Tambi&eacute;n podemos rescindir este Acuerdo
          inmediatamente despu&eacute;s de notific&aacute;rselo (A) con causa si
          tenemos el derecho de suspender bajo la Secci&oacute;n 6, (B) si
          nuestra relaci&oacute;n con un socio externo que proporciona software
          u otra tecnolog&iacute;a que utilizamos para proporcionar los
          Servicios vencen, finalizan o nos exigen que cambiemos la forma en que
          proporcionamos el software u otra tecnolog&iacute;a como parte de los
          Servicios, o (C) para cumplir con la ley o las solicitudes de
          entidades gubernamentales.
        </li>
      </ol>

      <p></p>

      <p>7.3 <strong>Efecto de la rescisi&oacute;n</strong>.</p>

      <p></p>

      <ol style="list-style-type: lower-alpha">
        <li>Generalmente. Tras la fecha de terminaci&oacute;n:</li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-roman">
        <li>
          excepto seg&uacute;n lo dispuesto en la Secci&oacute;n 7.3 (b), todos
          sus derechos bajo este Acuerdo terminan inmediatamente;
        </li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-roman">
        <li value="2">
          usted sigue siendo responsable de todas las tarifas y cargos en los
          que haya incurrido hasta la Fecha de terminaci&oacute;n y es
          responsable de las tarifas y los cargos en los que incurra durante el
          per&iacute;odo posterior a la terminaci&oacute;n descrito en la
          Secci&oacute;n 7.3 (b);
        </li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-roman">
        <li value="3">
          usted regresar&aacute; inmediatamente o, si se lo indicamos,
          destruir&aacute; todo el Contenido DRPM&eacute;xico que tenga en su
          poder; y
        </li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-roman">
        <li value="4">
          Las Secciones 4.1, 5, 7.3, 8 (excepto la licencia otorgada a usted en
          la Secci&oacute;n 8.3), 9, 10, 11, 13, 14 y 15 seguir&aacute;n
          aplic&aacute;ndose de acuerdo con sus t&eacute;rminos.
        </li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-alpha">
        <li value="2">
          Post-Rescisi&oacute;n. A menos que cancelemos su uso de las Ofertas de
          Servicios de conformidad con la Secci&oacute;n 7.2 (b), durante los 30
          d&iacute;as posteriores a la Fecha de terminaci&oacute;n:
        </li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-roman">
        <li>
          no tomaremos medidas para eliminar de los sistemas DRPM&eacute;xico Su
          contenido como resultado de la rescisi&oacute;n; y
        </li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-roman">
        <li value="2">
          le permitiremos recuperar Su Contenido de los Servicios solo si ha
          pagado todos los montos adeudados en virtud de este Acuerdo.
        </li>
      </ol>

      <p></p>

      <p>
        Para cualquier uso de los Servicios despu&eacute;s de la Fecha de
        terminaci&oacute;n, se aplicar&aacute;n los t&eacute;rminos de este
        Acuerdo y usted pagar&aacute; las tarifas correspondientes a las tarifas
        indicadas en la Secci&oacute;n 5.
      </p>

      <p></p>

      <h3>8. Derechos de propiedad.</h3>

      <p>
        <strong>8.1 Su contenido</strong>. Salvo lo dispuesto en esta
        Secci&oacute;n 8, no obtenemos derechos bajo este Acuerdo de usted (o
        sus licenciantes) sobre Su Contenido. Usted da su consentimiento para
        que usemos su contenido para proporcionarle los Servicios a usted y a
        los usuarios finales.
      </p>

      <p></p>

      <p>
        <strong>8.2 Derechos adecuados</strong>. Usted declara y nos garantiza
        que: (a) usted o sus licenciatarios poseen todos los derechos,
        t&iacute;tulos e intereses en y para Su Contenido y Sugerencias; (b)
        tiene todos los derechos sobre Su Contenido y Sugerencias necesarios
        para otorgar los derechos contemplados en este Acuerdo; y (c) ninguno de
        Su Contenido o el uso de Su Contenido o los Servicios por parte de los
        Usuarios Finales violar&aacute; la Pol&iacute;tica de Uso Aceptable.
      </p>

      <p></p>

      <p>
        <strong>8.3 Licencia de oferta de Servicios</strong>. Nosotros o
        nuestros otorgantes de licencias poseemos todos los derechos,
        t&iacute;tulos e intereses sobre las Ofertas de Servicios y todos los
        derechos de propiedad intelectual y tecnolog&iacute;a relacionados.
        Sujeto a los t&eacute;rminos de este Acuerdo, le otorgamos una licencia
        limitada, revocable, no exclusiva, no sublicenciable y no transferible
        para hacer lo siguiente: (a) acceder y utilizar los Servicios
        &uacute;nicamente de acuerdo con este Acuerdo; y (b) copiar y usar el
        Contenido de DRPM&eacute;xico &uacute;nicamente en relaci&oacute;n con
        el uso permitido de los Servicios. Salvo lo dispuesto en esta
        Secci&oacute;n 8.3, usted no obtiene ning&uacute;n derecho en virtud de
        este Acuerdo de parte nuestra, nuestras afiliadas o nuestros
        licenciantes sobre las Ofertas de Servicios, incluidos los derechos de
        propiedad intelectual relacionados. Es posible que se le proporcione
        parte del contenido de DRPM&eacute;xico y de terceros con una licencia
        separada, como la licencia de Apache, versi&oacute;n 2.0 u otra licencia
        de c&oacute;digo abierto. En caso de conflicto entre este Acuerdo y
        cualquier licencia separada, la licencia separada prevalecer&aacute; con
        respecto al Contenido de DRPM&eacute;xico o el Contenido de terceros que
        sea objeto de dicha licencia separada.
      </p>

      <p></p>

      <p>
        <strong>8.4 Restricciones de la licencia</strong>. Ni usted ni
        ning&uacute;n Usuario final utilizar&aacute;n los Servicios de ninguna
        manera ni para ning&uacute;n otro prop&oacute;sito que no sea el
        expresamente permitido por este Acuerdo. Ni usted ni ning&uacute;n
        Usuario final intentar&aacute;n, o intentar&aacute;n (a) modificar,
        distribuir, alterar, manipular, reparar o crear trabajos derivados de
        cualquier Contenido incluido en los Servicios (excepto en la medida en
        que el Contenido incluido en las Ofertas de Servicios) se le proporciona
        bajo una licencia separada que permite expresamente la creaci&oacute;n
        de trabajos derivados), (b) realizar ingenier&iacute;a inversa,
        desensamblar o descompilar los Servicios o aplicar cualquier otro
        proceso o procedimiento para derivar el c&oacute;digo fuente de
        cualquier software incluido en el Servicio (excepto en la medida en que
        la ley aplicable no permita esta restricci&oacute;n), (c) acceder o
        utilizar los Servicios de una manera destinada a evitar incurrir en
        tarifas o exceder los l&iacute;mites o cuotas de uso, o (d) revender o
        sublicenciar los Servicios. Solo puede usar la Marca de DRPM&eacute;xico
        de acuerdo con las Pautas de uso de marcas comerciales. No
        tergiversar&aacute; ni adornar&aacute; la relaci&oacute;n entre nosotros
        y usted (incluso expresando o insinuando que apoyamos, patrocinamos,
        respaldamos o contribuimos a usted o a sus esfuerzos comerciales). No
        implicar&aacute; ninguna relaci&oacute;n o afiliaci&oacute;n entre
        nosotros y usted, salvo que este Acuerdo lo permita expresamente.
      </p>

      <p></p>

      <p>
        <strong>8.5 Sugerencias.</strong> Si nos proporciona alguna sugerencia a
        nosotros o a nuestros afiliados, nosotros y nuestros afiliados tendremos
        derecho a utilizar las sugerencias sin restricciones. Por la presente,
        usted nos cede irrevocablemente todos los derechos, t&iacute;tulos e
        intereses en las Sugerencias y acepta brindarnos la asistencia que
        necesitemos para documentar, perfeccionar y mantener nuestros derechos
        sobre las Sugerencias.
      </p>

      <p></p>

      <h3>9. Indemnizaci&oacute;n.</h3>

      <p>
        9.1 General. Usted nos defender&aacute;, indemnizar&aacute; y nos
        eximir&aacute; de responsabilidad a nosotros, a nuestros afiliados y
        otorgantes de licencias, y a cada uno de sus respectivos empleados,
        funcionarios, directores y representantes de y contra cualquier
        P&eacute;rdida que surja de o se relacione con cualquier reclamo de
        terceros con respecto a: (a) su uso o el de cualquier Usuario final de
        los Servicios (incluidas las actividades realizadas en su cuenta de
        DRPM&eacute;xico y el uso por parte de sus empleados y personal); (b)
        incumplimiento de este Acuerdo o violaci&oacute;n de la ley aplicable
        por parte de usted, los Usuarios finales o Su contenido; o (c) una
        disputa entre usted y cualquier Usuario final. Nos reembolsar&aacute;
        los honorarios razonables de los abogados, as&iacute; como el tiempo y
        los materiales de nuestros empleados y contratistas dedicados a
        responder a cualquier citaci&oacute;n de un tercero u otro orden legal
        obligatorio o proceso asociado con reclamos de terceros descritos en (a)
        a (c) arriba a nuestras tarifas por hora vigentes en ese momento.
      </p>

      <p></p>

      <p>9.2 <strong>Propiedad intelectual</strong>.</p>

      <p></p>

      <ol style="list-style-type: lower-alpha">
        <li>
          Sujeto a las limitaciones de esta Secci&oacute;n 9, DRPM&eacute;xico
          lo defender&aacute; a usted y a sus empleados, funcionarios y
          directores contra cualquier reclamo de terceros que alegue que los
          Servicios infringen o se apropian indebidamente de los derechos de
          propiedad intelectual de ese tercero, y pagar&aacute; la cantidad de
          cualquier juicio o acuerdo final adverso.
        </li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-alpha">
        <li value="2">
          Sujeto a las limitaciones de esta Secci&oacute;n 9, usted
          defender&aacute; a DRPM&eacute;xico, sus afiliados y sus respectivos
          empleados, funcionarios y directores contra cualquier reclamo de
          terceros que alegue que su Contenido infringe o se apropia
          indebidamente de los derechos de propiedad intelectual de ese tercero,
          y pagar&aacute; el monto de cualquier sentencia o acuerdo final
          adverso.
        </li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-alpha">
        <li value="3">
          Ninguna de las partes tendr&aacute; obligaciones o responsabilidad en
          virtud de esta Secci&oacute;n 9.2 que surjan de la infracci&oacute;n
          por combinaciones de los Servicios o Su Contenido, seg&uacute;n
          corresponda, con cualquier otro producto, servicio, software, datos,
          contenido o m&eacute;todo. Adem&aacute;s, DRPM&eacute;xico no
          tendr&aacute; obligaciones ni responsabilidades derivadas del uso que
          usted o cualquier Usuario final haga de los Servicios despu&eacute;s
          de que DRPM&eacute;xico le haya notificado que suspenda dicho uso. Los
          recursos proporcionados en esta Secci&oacute;n 9.2 son los
          &uacute;nicos y exclusivos recursos para cualquier reclamo de terceros
          por infracci&oacute;n o apropiaci&oacute;n indebida de los derechos de
          propiedad intelectual por parte de los Servicios o de Su Contenido.
        </li>
      </ol>

      <p></p>

      <ol style="list-style-type: lower-alpha">
        <li value="4">
          Para cualquier reclamo cubierto por la Secci&oacute;n 9.2 (a),
          DRPM&eacute;xico, a su elecci&oacute;n, ya sea: (i) adquirir&aacute;
          los derechos para usar esa parte de los Servicios presuntamente
          infractores; (ii) reemplazar la parte supuestamente infractora de los
          Servicios con una alternativa no infractora; (iii) modificar la parte
          supuestamente infractora de los Servicios para que no sea infractora;
          o (iv) rescindir la parte supuestamente infractora de los Servicios o
          de este Acuerdo.
        </li>
      </ol>

      <p></p>

      <p>
        9.3 <strong>Proceso</strong>. Las obligaciones bajo esta Secci&oacute;n
        9 se aplicar&aacute;n solo si la parte que busca defensa o
        indemnizaci&oacute;n: (a) le da a la otra parte una notificaci&oacute;n
        por escrito oportuna del reclamo; (b) permite a la otra parte controlar
        la defensa y la soluci&oacute;n de la reclamaci&oacute;n; y (c) coopera
        razonablemente con la otra parte (a expensas de la otra parte) en la
        defensa y resoluci&oacute;n de la reclamaci&oacute;n. En ning&uacute;n
        caso, una de las partes aceptar&aacute; un acuerdo de cualquier reclamo
        que implique alg&uacute;n compromiso, que no sea el pago de dinero, sin
        el consentimiento por escrito de la otra parte.
      </p>

      <p></p>

      <h3>10. Renuncias.</h3>

      <p>
        LOS SERVICIOS SE PROPORCIONAN &quot;TAL CUAL&quot;. EXCEPTO EN LA MEDIDA
        EN QUE LO PROH&Iacute;BA LA LEY, O EN LA MEDIDA EN QUE SE APLIQUE
        CUALQUIER DERECHO ESTATUTARIO QUE NO SE PUEDA EXCLUIR, LIMITAR O
        RENUNCIAR, NOSOTROS, NUESTRAS AFILIADAS Y LICENCIANTES (A) NO HACEMOS
        DECLARACIONES NI GARANT&Iacute;AS DE NING&Uacute;N TIPO, YA SEA EXPRESA
        O IMPL&Iacute;CITA, DE LO CONTRARIO CON RESPECTO A LAS OFERTAS DE
        SERVICIO O EL CONTENIDO DE TERCEROS, Y (B) RECHAZO TODAS LAS
        GARANT&Iacute;AS, INCLUYENDO CUALQUIER GARANT&Iacute;A IMPL&Iacute;CITA
        O EXPRESA (I) DE COMERCIABILIDAD, CALIDAD SATISFACTORIA, APTITUD PARA UN
        PROP&Oacute;SITO PARTICULAR, NO DISFRUTE, II) QUE SURJA DE CUALQUIER
        CURSO DE TRATAMIENTO O USO COMERCIAL, (III) QUE LAS OFERTAS DEL SERVICIO
        O EL CONTENIDO DE TERCEROS SER&Aacute;N ININTERRUMPIDOS, LIBRES DE
        ERRORES O DE COMPONENTES DA&Ntilde;INOS, Y (IV) QUE CUALQUIER CONTENIDO
        SER&Aacute; SEGURO O NO DE LO CONTRARIO PERDIDO O ALTERADO.
      </p>

      <p></p>

      <h3>11. Limitaciones de responsabilidad.</h3>

      <p>
        NOSOTROS, NUESTROS AFILIADOS Y LICENCIANTES NO SEREMOS RESPONSABLES ANTE
        USTED POR NING&Uacute;N DA&Ntilde;O INDIRECTO, INCIDENTAL, ESPECIAL,
        CONSECUENTE O EJEMPLAR (INCLUYENDO DA&Ntilde;OS POR P&Eacute;RDIDA DE
        BENEFICIOS, INGRESOS, CLIENTES, OPORTUNIDADES, BUENA VOLUNTAD, USO O UNA
        PARTE DE DATOS), INCLUSO HA SIDO ADVERTIDO DE LA POSIBILIDAD DE TALES
        DA&Ntilde;OS. ADEM&Aacute;S, NI NOSOTROS NI NINGUNO DE NUESTROS
        AFILIADOS O LICENCIADORES SEREMOS RESPONSABLES DE NINGUNA
        COMPENSACI&Oacute;N, REEMBOLSO O DA&Ntilde;OS QUE SURJAN EN
        RELACI&Oacute;N CON: (A) SU INCAPACIDAD PARA UTILIZAR LOS SERVICIOS,
        INCLUYENDO COMO RESULTADO DE CUALQUIER (I) TERMINACI&Oacute;N O
        SUSPENSI&Oacute;N DE ESTE ACUERDO O SU USO O ACCESO A LOS SERVICIOS,
        (II) NUESTRA DESCONTINUACI&Oacute;N DE CUALQUIERA O TODOS LOS SERVICIOS,
        O, (III) SIN LIMITAR NINGUNA OBLIGACI&Oacute;N BAJO LOS ACUERDOS DE
        NIVEL DE SERVICIO, CUALQUIER TIEMPO DE INACTIVIDAD NO ANTICIPADO O NO
        PROGRAMADO DE TODOS O UNA PARTE DE LOS SERVICIOS POR CUALQUIER MOTIVO;
        (B) EL COSTO DE ADQUISICI&Oacute;N DE BIENES O SERVICIOS SUSTITUTOS; (C)
        CUALQUIER INVERSI&Oacute;N, GASTO O COMPROMISO DE SU PARTE EN
        RELACI&Oacute;N CON ESTE ACUERDO O SU USO O ACCESO A LAS OFERTAS DE
        SERVICIO; O (D) CUALQUIER ACCESO NO AUTORIZADO A, ALTERACI&Oacute;N O
        ELIMINACI&Oacute;N, DESTRUCCI&Oacute;N, DA&Ntilde;O, P&Eacute;RDIDA O
        FALTA DE ALMACENAMIENTO DE SU CONTENIDO U OTROS DATOS. EN CUALQUIER
        CASO, EXCEPTO POR LAS OBLIGACIONES DE PAGO CONFORME A LA SECCI&Oacute;N
        9.2, NUESTRA Y NUESTROS AFILIADOS Y LICENCIADORES LA RESPONSABILIDAD
        TOTAL DE ESTE ACUERDO NO EXCEDER&Aacute; LA CANTIDAD QUE USTED NOS PAGA
        REALMENTE BAJO ESTE ACUERDO POR EL SERVICIO QUE AUMENT&Oacute; EL
        D&Eacute;CIMO D&Iacute;A DE RECLAMACI&Oacute;N ANTES DE QUE SURGIERA LA
        RESPONSABILIDAD. LAS LIMITACIONES DE ESTA SECCI&Oacute;N 11 SE APLICAN
        &Uacute;NICAMENTE EN LA MEDIDA M&Aacute;XIMA PERMITIDA POR LA LEY
        APLICABLE.
      </p>

      <p></p>

      <h3>12. Modificaciones al Acuerdo.</h3>

      <p>
        Podemos modificar este Acuerdo (incluidas las Pol&iacute;ticas) en
        cualquier momento mediante la publicaci&oacute;n de una versi&oacute;n
        revisada en el Sitio DRPM&eacute;xico o notific&aacute;ndole de otra
        manera de acuerdo con la Secci&oacute;n 13.10; siempre que, sin embargo,
        proporcionemos un aviso con al menos 90 d&iacute;as de
        anticipaci&oacute;n de acuerdo con la Secci&oacute;n 13.10 para cambios
        adversos en cualquier Acuerdo de Nivel de Servicio. Sujeto al requisito
        de notificaci&oacute;n con 90 d&iacute;as de anticipaci&oacute;n con
        respecto a cambios adversos en los Acuerdos de nivel de servicio, los
        t&eacute;rminos modificados entrar&aacute;n en vigor al ser publicados
        o, si le notificamos por correo electr&oacute;nico, como se indica en el
        mensaje de correo electr&oacute;nico. Al continuar utilizando las
        Ofertas de Servicios despu&eacute;s de la fecha de vigencia de cualquier
        modificaci&oacute;n a este Acuerdo, usted acepta estar sujeto a los
        t&eacute;rminos modificados. Es su responsabilidad revisar el sitio
        DRPM&eacute;xico con regularidad para ver si hay modificaciones a este
        Acuerdo. Modificamos este Acuerdo por &uacute;ltima vez en la fecha
        indicada al final de este Acuerdo.
      </p>

      <p></p>

      <h3>13. Varios.</h3>

      <p>
        13.1 <strong>Cesi&oacute;n</strong>. No ceder&aacute; ni
        transferir&aacute; este Acuerdo ni ninguno de sus derechos y
        obligaciones en virtud de este Acuerdo sin nuestro consentimiento previo
        por escrito. Cualquier cesi&oacute;n o transferencia que viole esta
        Secci&oacute;n 13.1 ser&aacute; nula. Podemos ceder este Acuerdo sin su
        consentimiento (a) en relaci&oacute;n con una fusi&oacute;n,
        adquisici&oacute;n o venta de todos o sustancialmente todos nuestros
        activos, o (b) a cualquier filial o como parte de una
        reorganizaci&oacute;n corporativa; y con vigencia a partir de dicha
        cesi&oacute;n, el cesionario se considera sustituido por
        DRPM&eacute;xico como parte de este Acuerdo y DRPM&eacute;xico queda
        totalmente liberado de todas sus obligaciones y deberes a realizar en
        virtud de este Acuerdo. Sujeto a lo anterior, este Acuerdo ser&aacute;
        vinculante y redundar&aacute; en beneficio de las partes y sus
        respectivos sucesores y cesionarios autorizados.
      </p>

      <p></p>

      <p>
        13.2 <strong>Acuerdo completo</strong>. Este Acuerdo incorpora las
        Pol&iacute;ticas por referencia y es el acuerdo completo entre usted y
        nosotros con respecto al tema de este Acuerdo. Este Acuerdo reemplaza
        todas las representaciones, entendimientos, acuerdos o comunicaciones
        anteriores o contempor&aacute;neos entre usted y nosotros, ya sean
        escritos o verbales, con respecto al tema de este Acuerdo. No estaremos
        obligados ni objetaremos espec&iacute;ficamente a ning&uacute;n
        t&eacute;rmino, condici&oacute;n u otra disposici&oacute;n que sea
        diferente o adicional a las disposiciones de este Acuerdo (ya sea que
        altere materialmente este Acuerdo o no) incluyendo, por ejemplo,
        cualquier t&eacute;rmino, condici&oacute;n u otra disposici&oacute;n (a)
        presentada por usted en cualquier orden, recibo, aceptaci&oacute;n,
        confirmaci&oacute;n, correspondencia u otro documento, (b) relacionado
        con cualquier registro en l&iacute;nea, respuesta a cualquier Solicitud
        de Oferta, Solicitud de Propuesta, Solicitud de Informaci&oacute;n u
        otro cuestionario, o (c) relacionado con cualquier proceso de
        facturaci&oacute;n que env&iacute;e o nos solicite que completemos. Si
        los t&eacute;rminos de este documento no concuerdan con los
        t&eacute;rminos contenidos en cualquier Pol&iacute;tica, los
        t&eacute;rminos contenidos en este documento prevalecer&aacute;n,
        excepto que los T&eacute;rminos de Servicio controlar&aacute;n este
        documento.
      </p>

      <p></p>

      <p>
        13.3 <strong>Fuerza mayor</strong>. Nosotros y nuestras afiliadas no
        seremos responsables de ninguna demora o incumplimiento de ninguna
        obligaci&oacute;n en virtud de este Acuerdo cuando la demora o falla se
        deba a cualquier causa fuera de nuestro control razonable, incluidos
        casos fortuitos, disputas laborales u otros disturbios industriales,
        cortes el&eacute;ctricos o de energ&iacute;a, fallas de Servicios
        p&uacute;blicos u otras telecomunicaciones, terremotos, tormentas u
        otros elementos de la naturaleza, bloqueos, embargos, disturbios, actos
        u &oacute;rdenes del gobierno, actos de terrorismo o guerra.
      </p>

      <p></p>

      <p>
        13.4 <strong>Ley aplicable</strong>. Las leyes vigentes, sin referencia
        a las reglas de conflicto de leyes, rigen este Acuerdo y cualquier
        disputa de cualquier tipo que pueda surgir entre usted y nosotros.
      </p>

      <p></p>

      <p>
        13.5 <strong>Disputas</strong>. Cualquier disputa o reclamo relacionado
        de alguna manera con su uso de las Ofertas de Servicios, o con cualquier
        producto o servicio vendido o distribuido por DRPM&eacute;xico
        ser&aacute; adjudicado en los Tribunales del Gobierno de Jalisco, y
        usted acepta la jurisdicci&oacute;n y competencia exclusivas en los
        Tribunales del Gobierno de Jalisco, sujeto a las disposiciones
        adicionales a continuaci&oacute;n.
      </p>

      <p></p>

      <p>
        13.6 <strong>Cumplimiento comercial</strong>. En relaci&oacute;n con
        este Acuerdo, cada parte cumplir&aacute; con todas las leyes y
        regulaciones de control de importaci&oacute;n, reimportaci&oacute;n,
        sanciones, antiboicot, exportaci&oacute;n y control de
        reexportaci&oacute;n, incluidas todas las leyes y regulaciones que se
        aplican a una empresa mexicana, tales como el Reglamento de
        Administraci&oacute;n de Exportaciones, el Reglamento de Tr&aacute;fico
        Internacional de Armas y los programas de sanciones econ&oacute;micas
        implementados por la Oficina de Control de Activos Extranjeros. Para
        mayor claridad, usted es el &uacute;nico responsable del cumplimiento
        relacionado con la forma en que elige utilizar las Ofertas de Servicios,
        incluida la transferencia y el procesamiento de Su contenido, la
        provisi&oacute;n de Su contenido a los usuarios finales y la
        regi&oacute;n DRPM&eacute;xico en la que se encuentran ocurre lo
        anterior.
      </p>

      <p></p>

      <p>
        13.7 <strong>Contratistas independientes</strong>; Derechos no
        exclusivos. Usted y nosotros somos contratistas independientes, y este
        Acuerdo no se interpretar&aacute; en el sentido de crear una
        asociaci&oacute;n, empresa conjunta, agencia o relaci&oacute;n laboral.
        Ninguna de las partes, ni ninguno de sus respectivos afiliados, es un
        agente de la otra para ning&uacute;n prop&oacute;sito o tiene la
        autoridad para vincular a la otra. Ambas partes se reservan el derecho
        (a) de desarrollar o haber desarrollado para ella productos, Servicios,
        conceptos, sistemas o t&eacute;cnicas que sean similares o compitan con
        los productos, Servicios, conceptos, sistemas o t&eacute;cnicas
        desarrollados o contemplados por la otra parte. y (b) para ayudar a los
        desarrolladores o integradores de sistemas de terceros que puedan
        ofrecer productos o Servicios que compitan con los productos o Servicios
        de la otra parte.
      </p>

      <p></p>

      <p>
        13.8 <strong>Idioma</strong>. Todas las comunicaciones y avisos
        realizados o entregados de conformidad con este Acuerdo deben estar en
        idioma espa&ntilde;ol. Si proporcionamos una traducci&oacute;n de la
        versi&oacute;n en espa&ntilde;ol de este Acuerdo, la versi&oacute;n en
        espa&ntilde;ol del Acuerdo controlar&aacute; si hay alg&uacute;n
        conflicto.
      </p>

      <p></p>

      <p>
        13.9 <strong>Confidencialidad y publicidad</strong>. Puede usar la
        Informaci&oacute;n confidencial de DRPM&eacute;xico solo en
        relaci&oacute;n con su uso de los Servicios seg&uacute;n lo permitido en
        este Acuerdo. No divulgar&aacute; Informaci&oacute;n confidencial de
        DRPM&eacute;xico durante el Plazo o en ning&uacute;n momento durante el
        per&iacute;odo de 5 a&ntilde;os posterior a la finalizaci&oacute;n del
        Plazo. Tomar&aacute; todas las medidas razonables para evitar la
        divulgaci&oacute;n, difusi&oacute;n o uso no autorizado de la
        Informaci&oacute;n confidencial de DRPM&eacute;xico, incluidas, como
        m&iacute;nimo, las medidas que tome para proteger su propia
        informaci&oacute;n confidencial de naturaleza similar. No emitir&aacute;
        ning&uacute;n comunicado de prensa ni har&aacute; ninguna otra
        comunicaci&oacute;n p&uacute;blica con respecto a este Acuerdo o su uso
        de los Servicios.
      </p>

      <p></p>

      <h3>13.10 Aviso.</h3>

      <p>
        (a) Para ti. Podemos proporcionarle cualquier aviso en virtud de este
        Acuerdo: (i) publicando un aviso en el Sitio web de DRPM&eacute;xico; o
        (ii) enviar un mensaje a la direcci&oacute;n de correo
        electr&oacute;nico asociada a su cuenta. Los avisos que proporcionemos
        mediante la publicaci&oacute;n en el sitio DRPM&eacute;xico
        entrar&aacute;n en vigor al momento de su publicaci&oacute;n y los
        avisos que proporcionemos por correo electr&oacute;nico ser&aacute;n
        efectivos cuando enviemos el correo electr&oacute;nico. Es su
        responsabilidad mantener actualizada su direcci&oacute;n de correo
        electr&oacute;nico. Se considerar&aacute; que ha recibido cualquier
        correo electr&oacute;nico enviado a la direcci&oacute;n de correo
        electr&oacute;nico asociada con su cuenta cuando le enviemos el correo
        electr&oacute;nico, ya sea que lo reciba o no.
      </p>

      <p></p>

      <p>
        (b) Para nosotros. Para notificarnos en virtud de este Acuerdo, debe
        comunicarse con DRPM&eacute;xico mediante entrega personal o
        mensajer&iacute;a, seg&uacute;n corresponda, indicada para la Parte
        Contratante de DRPM&eacute;xico correspondiente en la Secci&oacute;n 15
        a continuaci&oacute;n. Podemos actualizar la direcci&oacute;n para
        recibir notificaciones publicando un aviso en el sitio DRPM&eacute;xico.
        Los avisos proporcionados por entrega personal entrar&aacute;n en vigor
        de inmediato. Las notificaciones proporcionadas por mensajer&iacute;a
        entrar&aacute;n en vigor un d&iacute;a h&aacute;bil despu&eacute;s de su
        env&iacute;o.
      </p>

      <p></p>

      <p>
        13.11 No hay terceros beneficiarios. Excepto como se establece en la
        Secci&oacute;n 9, este Acuerdo no crea ning&uacute;n derecho de
        beneficiario de terceros en ning&uacute;n individuo o entidad que no sea
        parte de este Acuerdo.
      </p>

      <p></p>

      <p>
        13.12 Sin exenciones. El hecho de que no hagamos cumplir cualquier
        disposici&oacute;n de este Acuerdo no constituir&aacute; una renuncia
        presente o futura de dicha disposici&oacute;n ni limitar&aacute; nuestro
        derecho a hacer cumplir dicha disposici&oacute;n en un momento
        posterior. Todas las exenciones por nuestra parte deben ser por escrito
        para que sean efectivas.
      </p>

      <p></p>

      <p>
        13.13 Divisibilidad. Si alguna parte de este Acuerdo se considera
        inv&aacute;lida o inaplicable, las partes restantes de este Acuerdo
        permanecer&aacute;n en pleno vigor y efecto. Cualquier parte
        inv&aacute;lida o inaplicable se interpretar&aacute; en el sentido y la
        intenci&oacute;n de la parte original. Si tal construcci&oacute;n no es
        posible, la parte inv&aacute;lida o inaplicable se separar&aacute; de
        este Acuerdo, pero el resto del Acuerdo permanecer&aacute; en pleno
        vigor y efecto.
      </p>

      <p></p>

      <h3>14. Política de Cancelación de Despliegue de Servicios.</h3>
      <p>
        14.1 En el caso de que el Cliente decida cancelar el despliegue de
        servicios contratados, se aplicará una tarifa de cancelación del 20%
        sobre el total del contrato acordado entre las partes, y sustentado por
        la firma de la propuesta economica compartida. Esta tarifa se aplicará
        para cubrir los costos administrativos y operativos incurridos hasta el
        momento de la cancelación.
      </p>
      <p>
        14.2 El Cliente reconoce y acepta que esta tarifa de cancelación es
        razonable y proporcional a los recursos y esfuerzos dedicados por el
        Proveedor para la preparación y planificación del despliegue de
        servicios. Además, el Cliente comprende que esta tarifa tiene como
        objetivo compensar los posibles costos y pérdidas asociados con la
        cancelación del contrato.
      </p>
      <p>
        14.3 Es importante destacar que esta tarifa de cancelación se aplicará
        independientemente de la razón de la cancelación por parte del Cliente,
        ya sea por motivos comerciales, técnicos o cualquier otro motivo.
      </p>
      <p>
        14.4 Al firmar el presente contrato, el Cliente reconoce haber leído,
        entendido y aceptado los términos y condiciones de esta política de
        cancelación.
      </p>

      <h3>15. Definiciones.</h3>

      <p>
        &ldquo;<strong>Pol&iacute;tica de uso aceptable</strong>&rdquo;
        significa la pol&iacute;tica ubicada en
        <a href="https://www.drpmexico.com/legal/uap#"
          >https://www.drpmexico.com/legal/uap#</a
        >&nbsp;(y cualquier sucesor o ubicaciones relacionadas designadas por
        nosotros), ya que podemos actualizarla de vez en cuando.
      </p>

      <p></p>

      <p>
        &quot;<strong>Pa&iacute;s de la cuenta</strong>&quot; es el pa&iacute;s
        asociado a su cuenta. Si ha proporcionado un n&uacute;mero de registro
        fiscal v&aacute;lido para su cuenta, el pa&iacute;s de su cuenta es el
        pa&iacute;s asociado con su registro fiscal. Si no ha proporcionado un
        registro fiscal v&aacute;lido, el pa&iacute;s de su cuenta es el
        pa&iacute;s donde se encuentra su direcci&oacute;n de
        facturaci&oacute;n.
      </p>

      <p></p>

      <p>
        &ldquo;<strong>Informaci&oacute;n de la cuenta</strong>&rdquo; significa
        informaci&oacute;n sobre usted que nos proporciona en relaci&oacute;n
        con la creaci&oacute;n o administraci&oacute;n de su cuenta
        DRPM&eacute;xico. Por ejemplo, la informaci&oacute;n de la cuenta
        incluye nombres, nombres de usuario, n&uacute;meros de tel&eacute;fono,
        direcciones de correo electr&oacute;nico e informaci&oacute;n de
        facturaci&oacute;n asociada con su cuenta DRPM&eacute;xico.
      </p>

      <p></p>

      <p>
        &quot;<strong>API</strong>&quot; significa una interfaz de programa de
        aplicaci&oacute;n.
      </p>

      <p></p>

      <p>
        &ldquo;<strong
          >Informaci&oacute;n confidencial de DRPM&eacute;xico</strong
        >&rdquo; significa toda la informaci&oacute;n no p&uacute;blica
        divulgada por nosotros, nuestras afiliadas, socios comerciales o
        nuestros empleados, contratistas o agentes o sus respectivos empleados,
        que se designa como confidencial o que, dada la naturaleza de la
        informaci&oacute;n o las circunstancias que rodean su
        divulgaci&oacute;n, razonablemente deber&iacute;a entenderse como
        confidencial. La informaci&oacute;n confidencial de DRPM&eacute;xico
        incluye: (a) informaci&oacute;n no p&uacute;blica relacionada con
        nuestra tecnolog&iacute;a o la de nuestros afiliados o socios
        comerciales, clientes, planes comerciales, actividades promocionales y
        de marketing, finanzas y otros asuntos comerciales; (b)
        informaci&oacute;n de terceros que estamos obligados a mantener
        confidencial; y (c) la naturaleza, contenido y existencia de cualquier
        discusi&oacute;n o negociaci&oacute;n entre usted y nosotros o nuestras
        afiliadas. La Informaci&oacute;n Confidencial de DRPM&eacute;xico no
        incluye ninguna informaci&oacute;n que: (i) est&eacute; o llegue a estar
        disponible p&uacute;blicamente sin incumplimiento de este Acuerdo; (ii)
        se puede demostrar mediante documentaci&oacute;n que lo conoc&iacute;a
        en el momento de recibirlo; (iii) se reciba de un tercero que no
        adquiri&oacute; o divulg&oacute; el mismo mediante un acto
        il&iacute;cito o il&iacute;cito; o (iv) se puede demostrar mediante
        documentaci&oacute;n que ha sido desarrollado de forma independiente por
        usted sin hacer referencia a la Informaci&oacute;n confidencial de
        DRPM&eacute;xico.
      </p>

      <p></p>

      <p>
        &ldquo;<strong>Contenido de DRPM&eacute;xico</strong>&rdquo; significa
        Contenido que nosotros o cualquiera de nuestras afiliadas ponemos a
        disposici&oacute;n en relaci&oacute;n con los Servicios o en el Sitio
        DRPM&eacute;xico para permitir el acceso y el uso de los Servicios,
        incluidas las API; WSDL; Documentaci&oacute;n; C&oacute;digo de muestra;
        bibliotecas de software; herramientas de l&iacute;nea de comandos;
        pruebas de concepto; plantillas; y otra tecnolog&iacute;a relacionada
        (incluida cualquiera de las anteriores proporcionadas por nuestro
        personal). El Contenido DRPM&eacute;xico no incluye los Servicios ni el
        Contenido de terceros.
      </p>

      <p></p>

      <p>
        &quot;<strong>Parte Contratante de DPRMX</strong>&quot; significa la
        parte identificada en la tabla a continuaci&oacute;n, seg&uacute;n el
        Pa&iacute;s de su Cuenta. Si cambia el Pa&iacute;s de su Cuenta a uno
        identificado como una Parte Contratante de DRPM&eacute;xico diferente a
        continuaci&oacute;n, acepta que este Acuerdo se asigne a la nueva Parte
        Contratante de DRPM&eacute;xico seg&uacute;n la Secci&oacute;n 13.1 sin
        que ninguna de las partes requiera ninguna acci&oacute;n adicional.
      </p>

      <p></p>

      <table border="1" cellpadding="0" cellspacing="0">
        <tbody>
          <tr>
            <td style="width: 208px">
              <p style="text-align: center">Pa&iacute;s</p>
            </td>
            <td style="width: 208px">
              <p style="text-align: center">Parte Contratante</p>
            </td>
            <td style="width: 208px">
              <p style="text-align: center">Direcci&oacute;n</p>
            </td>
          </tr>
          <tr>
            <td style="width: 208px">
              <p style="text-align: center">M&eacute;xico</p>
            </td>
            <td style="width: 208px">
              <p style="text-align: center">
                DRP Cloud M&eacute;xico SAPI de CV
              </p>
            </td>
            <td style="width: 208px">
              <p style="text-align: center">
                Av. de las Am&eacute;ricas 1501-Piso 20, Sao Paulo, Providencia,
                44630 Guadalajara, Jal
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <p></p>

      <p>
        &ldquo;<strong>La Marca DRPM&eacute;xico</strong>&rdquo; significa
        cualquier marca comercial, marca de servicio, servicio o nombres
        comerciales, logotipos y otras designaciones de DRPM&eacute;xico y sus
        afiliadas que podamos poner a su disposici&oacute;n en relaci&oacute;n
        con este Acuerdo.
      </p>

      <p></p>

      <p>
        &ldquo;<strong>Sitio DRPM&eacute;xico</strong>&rdquo; significa
        http://www.drpmexico.com (y cualquier sitio sucesor o relacionado
        designado por nosotros), que podamos actualizar de vez en cuando.
      </p>

      <p></p>

      <p>
        &ldquo;<strong>Contenido</strong>&rdquo; significa software (incluidas
        im&aacute;genes de m&aacute;quinas), datos, texto, audio, video o
        im&aacute;genes.
      </p>

      <p></p>

      <p>
        &ldquo;<strong>Documentaci&oacute;n</strong>&rdquo; significa las
        gu&iacute;as de usuario y las gu&iacute;as de administraci&oacute;n (en
        cada caso excluyendo el contenido referenciado a trav&eacute;s de un
        hiperv&iacute;nculo) para los Servicios ubicados en
        <a href="https://www.drpmexico.com">http://www.drpmexico.com/</a> (y
        cualquier lugar sucesor o relacionado designado por nosotros), como tal
        Las gu&iacute;as de usuario y las gu&iacute;as de administraci&oacute;n
        pueden ser actualizadas por DRPM&eacute;xico de vez en cuando.
      </p>

      <p></p>

      <p>
        &quot;<strong>Usuario final</strong>&quot; significa cualquier individuo
        o entidad que, directa o indirectamente a trav&eacute;s de otro usuario:
        (a) accede o utiliza Su contenido; o (b) accede o utiliza de otro modo
        las Ofertas de Servicios de su cuenta. El t&eacute;rmino &quot;Usuario
        final&quot; no incluye a personas o entidades cuando acceden o utilizan
        los Servicios o cualquier Contenido en su propia cuenta
        DRPM&eacute;xico, en lugar de hacerlo en su cuenta.
      </p>

      <p></p>

      <p>
        &quot;<strong>Leyes vigentes</strong>&quot; y &quot;Tribunales
        vigentes&quot; significan, para cada Parte Contratante de
        DRPM&eacute;xico, las leyes y tribunales establecidos en la siguiente
        tabla:
      </p>

      <p></p>

      <table border="1" cellpadding="0" cellspacing="0">
        <tbody>
          <tr>
            <td style="width: 208px">
              <p style="text-align: center">Parte Contratante</p>
            </td>
            <td style="width: 208px">
              <p style="text-align: center">Leyes vigentes</p>
            </td>
            <td style="width: 208px">
              <p style="text-align: center">Tribunales vigentes</p>
            </td>
          </tr>
          <tr>
            <td style="width: 208px">
              <p style="text-align: center">DRP Cloud Mexico SAPI de CV</p>
            </td>
            <td style="width: 208px">
              <p style="text-align: center">Las leyes de M&eacute;xico</p>
            </td>
            <td style="width: 208px">
              <p style="text-align: center">
                Los tribunales estatales o federales en Guadalajara, Jalisco
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <p></p>

      <p>
        &ldquo;<strong>Impuestos indirectos</strong>&rdquo; significa impuestos
        y aranceles aplicables, incluidos, entre otros, IVA, impuesto de
        servicio, impuestos especiales, impuestos sobre ventas y transacciones e
        impuesto sobre ingresos brutos.
      </p>

      <p></p>

      <p>
        &quot;<strong>P&eacute;rdidas</strong>&quot; significa cualquier
        reclamo, da&ntilde;o, p&eacute;rdida, responsabilidad, costo y gasto
        (incluidos los honorarios razonables de abogados).
      </p>

      <p></p>

      <p>
        &quot;<strong>Pol&iacute;ticas</strong>&quot; significa la
        Pol&iacute;tica de uso aceptable, el Aviso de privacidad, los
        T&eacute;rminos del sitio, los T&eacute;rminos del servicio, las Pautas
        de uso de marcas comerciales, todas las restricciones descritas en el
        Contenido de DRPM&eacute;xico y en el Sitio de DRPM, y cualquier otra
        pol&iacute;tica o t&eacute;rminos mencionados o incorporados en este
        Acuerdo, pero no incluye documentos t&eacute;cnicos u otros materiales
        de marketing a los que se hace referencia en el sitio DRPM&eacute;xico.
      </p>

      <p></p>

      <p>
        &ldquo;<strong>Aviso de privacidad</strong>&rdquo; significa el aviso de
        privacidad
        <a href="https://www.drpmexico.com/legal/privacy#"
          >https://www.drpmexico.com/legal/privacy#</a
        >
        (y cualquier sucesor o ubicaciones relacionadas que designemos), ya que
        podemos actualizarlo de vez en cuando.
      </p>

      <p></p>

      <p>
        &quot;<strong>Servicios</strong>&quot; significa cada uno de los
        Servicios que ponemos a disposici&oacute;n nosotros o nuestras
        afiliadas, incluidos los Servicios web descritos en los T&eacute;rminos
        de servicio, (incluidas las API asociadas), el Contenido
        DRPM&eacute;xico, las Marcas DRPM&eacute;xico y cualquier otro producto
        o servicio proporcionado por nosotros en virtud de este Acuerdo. Los
        Servicios no incluyen contenido de terceros.
      </p>

      <p></p>

      <p>
        &quot;<strong>Atributos del servicio</strong>&quot; se refiere a los
        datos de uso del servicio relacionados con su cuenta, como
        identificadores de recursos, etiquetas de metadatos, roles de seguridad
        y acceso, reglas, pol&iacute;ticas de uso, permisos, estad&iacute;sticas
        de uso y an&aacute;lisis.
      </p>

      <p></p>

      <p>
        &quot;<strong>Acuerdo de nivel de servicio</strong>&quot; significa
        todos los acuerdos de nivel de servicio que ofrecemos con respecto a los
        Servicios y que publicamos en el Sitio DRPM&eacute;xico, ya que podemos
        actualizarlos de vez en cuando. Los acuerdos de nivel de servicio que
        ofrecemos con respecto a los Servicios se encuentran en
        <a href="https://www.drpmexico.com/legal/sla#"
          >https://www.drpmexico.com/legal/sla#</a
        >
        (y cualquier sucesor o ubicaciones relacionadas designadas por
        DRPM&eacute;xico), que DRPM&eacute;xico puede actualizar de vez en
        cuando.
      </p>

      <p></p>

      <p>
        &ldquo;<strong>T&eacute;rminos de servicio</strong>&rdquo; significa los
        derechos y restricciones para Servicios particulares ubicados en
        <a href="https://www.drpmexico.com/legal/terms#"
          >https://www.drpmexico.com/legal/terms#"</a
        >
        (y cualquier sucesor o ubicaciones relacionadas designadas por
        nosotros), seg&uacute;n lo podamos actualizar de vez en cuando.
      </p>

      <p></p>

      <p>
        &ldquo;<strong>T&eacute;rminos del sitio</strong>&rdquo; significa los
        t&eacute;rminos de uso que se encuentran en
        <a href="https://www.drpmexico.com/legal/terms#"
          >https://www.drpmexico.com/legal/terms#</a
        >&nbsp;(y cualquier sucesor o ubicaciones relacionadas designadas por
        nosotros), que podamos actualizar de vez en cuando.
      </p>

      <p></p>

      <p>
        &quot;<strong>Sugerencias</strong>&quot; hace referencia a todas las
        mejoras sugeridas a las Ofertas de Servicios que nos proporciona.
      </p>

      <p></p>

      <p>
        &ldquo;<strong>T&eacute;rmino</strong>&rdquo; significa el
        t&eacute;rmino de este Acuerdo descrito en la Secci&oacute;n 7.1.
      </p>

      <p></p>

      <p>
        &ldquo;<strong>Fecha de terminaci&oacute;n</strong>&rdquo; significa la
        fecha efectiva de terminaci&oacute;n provista de acuerdo con la
        Secci&oacute;n 7, en un aviso de una parte a la otra.
      </p>

      <p></p>

      <p>
        &ldquo;<strong>Contenido de terceros</strong>&rdquo; significa Contenido
        puesto a su disposici&oacute;n por cualquier tercero en el Sitio
        DRPM&eacute;xico o junto con los Servicios.
      </p>

      <p></p>

      <p>
        &ldquo;<strong>Pautas de uso de marcas registradas</strong>&rdquo; se
        refiere a las pautas y la licencia de marcas registradas que se
        encuentran en
        <a href="https://www.drpmexico.com/">https://www.drpmexico.com/</a
        >&nbsp;(y cualquier sucesor o ubicaciones relacionadas designadas por
        nosotros), ya que podemos actualizarlas de vez en cuando.
      </p>

      <p></p>

      <p>
        &quot;<strong>Su Contenido</strong>&quot; se refiere al Contenido que
        usted o cualquier Usuario final nos transfiere para su procesamiento,
        almacenamiento o alojamiento por parte de los Servicios en
        relaci&oacute;n con su cuenta DRPM&eacute;xico y cualquier resultado
        computacional que usted o cualquier Usuario final derive de lo anterior
        a trav&eacute;s de su uso de los Servicios. Por ejemplo, Su Contenido
        incluye Contenido que usted o cualquier Usuario final almacena en
        DRPM&eacute;xico. Su contenido no incluye informaci&oacute;n de cuenta.
      </p>

      <p></p>

      <p></p>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    location.href = "#";
  },
};
</script>

<style></style>
