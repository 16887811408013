<template>
    <section>
        <div class="text-justify">
            <h2>Pol&iacute;tica de uso aceptable</h2>

            <p></p>

            <p>Esta Pol&iacute;tica de uso aceptable (&quot;Pol&iacute;tica&quot;) rige su uso de los servicios ofrecidos por DRP Cloud M&eacute;xico SAPI de CV y sus afiliados (&quot;Servicios&quot;) y nuestro (s) sitio (s) web, incluido <a href="https://www.drpmexico.com">http://www.drpmexico.com</a> (&quot;DRPM&eacute;xico Sitio&quot;). Podemos modificar esta Pol&iacute;tica publicando una versi&oacute;n revisada en el sitio DRPM&eacute;xico. Al utilizar los Servicios o acceder al Sitio DRPM&eacute;xico, acepta la &uacute;ltima versi&oacute;n de esta Pol&iacute;tica.</p>

            <p></p>

            <p>No puede usar, facilitar o permitir que otros usen los Servicios o el Sitio DRPM&eacute;xico:</p>

            <p></p>

            <ul>
                <li>por cualquier actividad ilegal o fraudulenta;</li>
                <li>violar los derechos de otros;</li>
                <li>amenazar, incitar, promover o alentar activamente la violencia, el terrorismo u otros da&ntilde;os graves;</li>
                <li>para cualquier contenido o actividad que promueva la explotaci&oacute;n o el abuso sexual infantil;</li>
                <li>violar la seguridad, integridad o disponibilidad de cualquier usuario, red, computadora o sistema de comunicaciones, aplicaci&oacute;n de software, red o dispositivo inform&aacute;tico;</li>
                <li>para distribuir, publicar, enviar o facilitar el env&iacute;o de correos electr&oacute;nicos masivos no solicitados u otros mensajes, promociones, publicidad o solicitudes (o &quot;spam&quot;).</li>
            </ul>

            <p></p>

            <h3>Investigaci&oacute;n y ejecuci&oacute;n</h3>

            <p></p>

            <p>Podemos investigar cualquier sospecha de violaci&oacute;n de esta Pol&iacute;tica y eliminar o deshabilitar el acceso a cualquier contenido o recurso que viole esta Pol&iacute;tica. Acepta cooperar con nosotros para remediar cualquier infracci&oacute;n.</p>

            <p></p>

            <p>Al determinar si ha habido una violaci&oacute;n de esta Pol&iacute;tica, podemos considerar su capacidad y voluntad para cumplir con esta Pol&iacute;tica, incluidas las pol&iacute;ticas y los procesos que tiene implementados para prevenir o identificar y eliminar cualquier contenido o actividad prohibidos.</p>

            <p></p>

            <h3>Notificaci&oacute;n de infracciones</h3>

            <p></p>

            <p>Para denunciar cualquier infracci&oacute;n de esta Pol&iacute;tica, env&iacute;e un correo electr&oacute;nico sobre la infracci&oacute;n a <a href="mailto:abuse@drpmexico.com">abuse@drpmexico.com</a>.</p>
        </div>
    </section>
</template>

<script>
export default {
    mounted(){
        location.href = '#'
    }
}
</script>

<style>

</style>